import Vue from 'vue';
import VueRouter from 'vue-router';
import { getUserData } from '@routes';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login'),
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('token');

            if (token) {
                localStorage.removeItem('token');
            }

            next();
        }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('../views/Login'),
        props: { isAdmin: true },
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('token');

            if (token) {
                localStorage.removeItem('token');
            }

            next();
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/Register'),
        meta: {
            module: 'register'
        }
    },
    {
        path: '',
        component: () => import('../views/Base'),
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('token');
            const user = JSON.parse(localStorage.getItem('user'));
            const sessionUser = JSON.parse(localStorage.getItem('sessionUser'));

            if (token) {
                if (user) {
                    Vue.axios.post(getUserData(sessionUser.id), { supplierId: user.id })
                        .then(response => {
                            if (response.data.code === 'OK') {
                                localStorage.setItem('user', JSON.stringify(response.data.user));

                                next();
                            } else if (response.data.code === 'NOK') {
                                next({ name: 'Login', replace: true });
                            }
                        })
                        .catch(error => {
                            next({ name: 'Login', replace: true });
                        });
                } else {
                    next({ name: 'Login', replace: true });
                }
            } else {
                next({ name: 'Login', replace: true });
            }
        },
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import('../views/Home'),
                meta: {
                    module: 'home'
                }
            },
            {
                path: '/account',
                name: 'Account',
                component: () => import('../views/Register'),
                meta: {
                    module: 'Account'
                }
            },
            {
                path: '/Invoices',
                name: 'Invoices',
                component: () => import('../views/Invoice/Index'),
                meta: {
                    module: 'invoices'
                }
            },
            {
                path: '/new',
                name: 'newInvoice',
                component: () => import('../views/Invoice/form.vue'),
                meta: {
                    module: 'invoices'
                }
            },
            {
                path: '/invoice/show',
                name: 'invoiceDetail',
                component: () => import('../views/Invoice/show.vue'),
                meta: {
                    module: 'invoices'
                }
            },
            {
                path: '/purchaseorder',
                name: 'PurchaseOrder',
                component: () => import('../views/PurchaseOrder/Index'),
                meta: {
                    module: 'PurchaseOrder'
                }
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name === 'Login' || to.name === 'register' || to.name === 'Admin') {
        next();
    } else {
        const token = localStorage.getItem('token');

        if (token) {
            next();
        } else {
            next({ name: 'Login', replace: true });
        }
    }
});

export default router;
